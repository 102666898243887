:root {
  --padding-left-content: 272px;
}

html,
body {
  height: 100%;
}

body {
  position: relative;
  font-size: 14px;
  color: #1b1a1d;
  background: #ffffff;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;

  @media (min-width: 768px) {
    padding-left: var(--padding-left-content);
  }

  @media (max-width: 767px) {
    padding-top: 48px;
  }
}

*,
:before,
:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* {
  -webkit-locale: auto;
}

svg {
  pointer-events: none;
}

input,
textarea,
button {
  border: none;
  outline: none;
  border-radius: 0;
  font-family: 'Inter', sans-serif;
}

input[type='text'],
input[type='password'],
input[type='tel'],
input[type='email'],
input[type='number'],
textarea,
button {
  -webkit-appearance: none;
}

button {
  background: transparent;
}

input::-webkit-credentials-auto-fill-button,
input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
}

input::-ms-reveal,
input::-ms-clear {
  width: 0;
  height: 0;
}

fieldset {
  border: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: normal;
  color: inherit;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
}

li {
  list-style: none;
}

hr {
  border: none;
  background-color: #f0f1f2;
  color: #f0f1f2;
  height: 1px;
}

.react-kanban-column {
  margin: 0;

  &:not(:last-child) {
    margin-right: 16px;
  }
}

.react-kanban-board {
  @supports not selector(::-webkit-scrollbar) {
    scrollbar-color: #abbed1 #f4f6f8; // for firefox only
    scrollbar-width: thin; // for firefox only
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(244, 246, 248, 1); /*Цвет скролла*/
    margin: 0 3px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px; /*Закругление ползунка*/
    background-color: #abbed1; /*Цвет ползунка*/
    opacity: 1;
    transition: background-color 0.2s ease-in-out;
  }

  &::-webkit-scrollbar {
    height: 7px; /*Толщина скролла*/
  }
}

.article {
  p,
  ul {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  ul {
    li {
      position: relative;
      padding-left: 15px;
      margin-bottom: 10px;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 8px;
        width: 5px;
        height: 5px;
        background: #333;
        border-radius: 50%;
      }
    }
  }

  a {
    color: #0393ff;
  }
}

.MuiBackdrop-root {
  background-color: rgba(48, 65, 86, 0.7) !important;
}
// .simplebar-content-wrapper{
//   overflow: visible !important;
// }
// .simplebar-mask{
//   overflow: visible !important;

// } смотреть проект - задача - время - меню изменения времени если есть 2+ элементов списка, предположительно конфликт симплбара и меню пока добавил большие отступы

.Toastify {
  a {
    color: #0393ff;
  }
}
