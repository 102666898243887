.lightbox-file-list {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  gap: 16px;

  &:empty {
    display: none;
  }
}

.fancybox__container {
  z-index: 1700 !important;
}

.lightbox-file-list__wrapper {
  width: calc(100% / 3 - 16px * 2 / 3);
  padding: 8px;
  height: auto;
  min-height: 149px;
  border: 1px solid #e1e5e9;
  background-color: #f4f6f8;
  border-radius: 12px;
  margin: 0;
  position: relative;

  @media (max-width: 767px) {
    width: calc(100% / 2 - 16px * 1 / 2);
  }
}
